import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from 'react-helmet';
import Layout from "../components/layout"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { Grid } from "@material-ui/core/"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SimpleTabs from "../components/Slider/Slider"
import ContactUs from "../components/Contactus"
import ProcessImage from "../assets/process.jpg"
import ProcessImage1 from "../assets/process-1.jpg"
import ProcessImage2 from "../assets/process-2.jpg"
import ProcessImage3 from "../assets/process-3.jpeg"
import ProcessImage4 from "../assets/process-4.jpeg"
import ProcessImage5 from "../assets/process-5.jpeg"
import ProcessImage6 from "../assets/process-6.png"
import MapImage from "../assets/map.png"
import HeroImage from "../assets/BlueCrab.png"
import MapBox from "../assets/map_box.png"
import flag_images from "../components/FlagsImages"
import Test1 from "../assets/officeImage.png"
import Test2 from "../assets/factoryimage.png"
import Icon1 from "../assets/icon1.png"
import Icon2 from "../assets/icon2.png"
import Icon3 from "../assets/icon3.png"
import Icon4 from "../assets/icon4.png"
import Icon5 from "../assets/icon5.png"
import Icon6 from "../assets/icon6.png"
import "./index.css"
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper/core"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "../components/Slider/Slider.css"
import { useStyles } from "../theme/theme"


// install Swiper modules
SwiperCore.use([Autoplay, Navigation])
const HomePage = ({ data }) => {
  const classes = useStyles()

  const procedure = {
    ProcessImage,
    ProcessImage1,
    ProcessImage2,
    ProcessImage3,
    ProcessImage4,
    ProcessImage5,
    ProcessImage6
  }
  const [selected, setSelected] = useState(procedure.ProcessImage)
  const [focuss, setFocuss] = useState(true)
  const [focuss1, setFocuss1] = useState(false)
  const [focuss2, setFocuss2] = useState(false)
  const [focuss3, setFocuss3] = useState(false)


  useEffect(() => {
    setTimeout(() => {
      setSelected(procedure.ProcessImage1)
    }, 1500);
  }, [])

  const handleClick1 = () => {
    setSelected(procedure.ProcessImage)
    setFocuss(true)
    setFocuss1(false)
    setFocuss2(false)
    setFocuss3(false)
  }
  const handleClick2 = () => {
    setSelected(procedure.ProcessImage1)
    setFocuss(false)
    setFocuss1(true)
    setFocuss2(false)
    setFocuss3(false)
  }
  const handleClick3 = () => {
    setSelected(procedure.ProcessImage2)
    setFocuss(false)
    setFocuss1(false)
    setFocuss2(true)
    setFocuss3(false)
  }
  const handleClick4 = () => {
    setSelected(procedure.ProcessImage3)
    setFocuss(false)
    setFocuss1(false)
    setFocuss2(false)
    setFocuss3(true)
  }

  // function scrolling() {
  //   scoll.scrollMore(800)
  // }
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("md"))
  const isMedSmall = useMediaQuery(theme.breakpoints.down(1023))
  const isXtraSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"))
  const isMob = useMediaQuery(theme.breakpoints.down(500))
  const isMed = useMediaQuery(theme.breakpoints.down(1280))

  // const pagination = {
  //   "clickable": true,
  //   "renderBullet": function (index, className) {

  //           return '<span class=\"' + className + '\">' + '</span>';

  //         }
  // }



  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>Ocean Fishery | Quality Frozen Seafood Company </title>
        <description>Description of your homepage.</description>
      </Helmet>
      {/*
      {data.allWpProduct.edges.map(mydata => (
        <div>
          <h1>Title</h1>
          <Link to={`/product/${mydata.node.slug}`}>
          <p>{mydata.node.name}</p>
          <img src={mydata.node.image.sourceUrl} />
          </Link>
        </div>
      ))}
      */}
      <Grid
        container
        spacing={0}
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className={classes.hero_section}
      >
        <Grid
          item
          sm={4}
          xs={10}
          className={classes.hero_section_content}
          style={{ textAlign: `${isXSmall ? "center" : ""}` }}
          data-sal="slide-right"
          data-sal-delay="0"
          data-sal-duration="500"
          data-sal-easing="ease-in-back"
        >
          <span>Ocean Fisheries</span>
          <h1>
            Wild-Caught
            <br />
            Sustainable
            <br />
            Seafood
          </h1>
          <Link to="/products">
            <button className={classes.btn_variant_1}>
              Explore Now{" "}
              <ArrowForwardIosIcon
                style={{ fontSize: "16px", paddingTop: "3px" }}
                className="arrow_icon"
              />
            </button>
          </Link>
        </Grid>

        <Grid
          item
          sm={6}
          xs={10}
          className={classes.hero_section_image}
          data-sal="zoom-in"
          data-sal-delay="0"
          data-sal-duration="1500"
          data-sal-easing="ease-out"
        >
          <img src={HeroImage} />
        </Grid>
      </Grid>
      <div className="product-section" id="our-products" name="our-products">
        <SimpleTabs data={data} />
      </div>

      <div className={classes.title}><h3>Our Offices</h3></div>



      <Grid
        container
        id="about"
        spacing={isXtraSmall ? 5 : ""}
        direction="row"
        justify="space-evenly"
        alignItems="center"
        className={classes.about_section}
      >

        <Grid
          container
          id="about"
          xs={12}
          sm={10}
          md={5}
          lg={4}
          xl={5}
          spacing={isXtraSmall ? 5 : ""}
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          className={classes.about_section}
        >


          <Grid
            item

            data-sal="slide-up"
            data-sal-delay="0"
            data-sal-duration="500"
            data-sal-easing="ease-out-back"
          >
            <div className="container">
              <div className="image">
                <img src={Test1} alt="" />
              </div>
            </div>
          </Grid>

          <Grid
            item

            className={classes.about_content}
            data-sal="slide-left"
            data-sal-delay="0"
            data-sal-easing="ease-in"
          >
            <h3>Head Office</h3>
            {/* <h2>Distribution and Sourcing</h2> */}
            <p>
              The office in Samut Sakhon, Thailand serves as our homebase for the management team, which consists of experienced professionals in the seafood industry. With their extensive knowledge and expertise, they oversee every aspect of the business, from sourcing the finest quality seafood to managing the processing and distribution of our products.</p>
          </Grid>


        </Grid>

        <Grid
          container
          id="about"
          xs={12}
          sm={10}
          md={5}
          lg={4}
          xl={5}
          spacing={isXtraSmall ? 5 : ""}
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          className={classes.about_section}
        >


          <Grid
            item

            data-sal="slide-up"
            data-sal-delay="0"
            data-sal-duration="500"
            data-sal-easing="ease-out-back"
          >
            <div className="container">
              <div className="image">
                <img src={Test2} alt="" />
              </div>
            </div>
          </Grid>

          <Grid
            item

            className={classes.about_content}
            data-sal="slide-left"
            data-sal-delay="0"
            data-sal-easing="ease-in"
          >
            <h3>Factory</h3>
            {/* <h2>Distribution and Sourcing</h2> */}
            <p>
              Our factory in Petchaburi is strategically located near the main port, which allows us to have quick and easy access to fresh seafood from various regions. This enables us to ensure the quality of our products while minimizing the time it takes to transport them to our processing facilities.
            </p>
          </Grid>


        </Grid>

      </Grid>
      <Grid item

        container spacing={12}
        className={classes.process_section_container_new}>
        <h3>Why Us</h3>
        <Grid


          container
          spacing={12}
          className={classes.process_section_new}>

          <Grid
            lg={3}
            item

            spacing={3}
            className={classes.process_card_new}
          >
            <img src={Icon1} alt="" />
            <h4>A global network of reliable
              suppliers</h4>
            <p>Our company has established a robust global network of reliable suppliers to ensure that we always have access to the highest quality seafood. We work directly with fishermen and suppliers in various regions to ensure that our products are always fresh, sustainably sourced, and of the highest quality.</p>
          </Grid>

          <Grid
            lg={3}
            item

            spacing={3}
            className={classes.process_card_new}
          >
            <img src={Icon2} alt="" />
            <h4>From the sea to you</h4>
            <p>Our company prides itself on our "from the sea to you" approach. This means that we oversee the entire process from catching the fish to processing and freezing it, to ensure the highest quality and freshest seafood for our customers. We believe that this level of control is essential to providing the best products possible.</p>
          </Grid><Grid
            lg={3}
            item

            spacing={3}
            className={classes.process_card_new}
          >
            <img src={Icon3} alt="" />
            <h4>A diverse and skillful team that cares</h4>
            <p>Our team is made up of diverse and highly skilled professionals who share a passion for delivering the best quality seafood. We believe in building strong relationships with our customers and suppliers, and we care deeply about the impact of our products on the environment.</p>
          </Grid><Grid
            lg={3}
            item

            spacing={3}
            className={classes.process_card_new}
          >
            <img src={Icon4} alt="" />
            <h4>No compromises on quality</h4>
            <p>At our company, we believe that quality is non-negotiable. We do not compromise on quality, and we always strive to exceed our customers' expectations. We employ strict quality control measures at every stage of the process to ensure that our products meet the highest standards.</p>
          </Grid><Grid
            lg={3}
            item

            spacing={3}
            className={classes.process_card_new}
          >
            <img src={Icon5} alt="" />
            <h4>Latest technology and machinery</h4>
            <p>Our company is committed to using the latest technology and machinery to provide the best products possible. We invest in state-of-the-art processing and freezing equipment to ensure that our products are of the highest quality and meet the most stringent standards.</p>
          </Grid><Grid
            lg={3}
            item

            spacing={3}
            className={classes.process_card_new}
          >
            <img src={Icon6} alt="" />
            <h4>Sustainability at heart</h4>
            <p>Sustainability is at the heart of everything we do. We are committed to responsible sourcing and work closely with our suppliers to ensure that our products are sustainably sourced. We are also continuously exploring new ways to reduce our impact on the environment and to promote sustainable practices in the seafood industry.</p>
          </Grid>


        </Grid>
      </Grid>


      {/* {
        isXtraSmall ? (
          <div id="mob-production">
            <Grid container spacing={5} className={classes.process_section_xs}>
              <Grid item xs={12} className="title">
                <h3>Why Us?</h3>
              </Grid>

              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                loop={true}
                autoplay={{ delay: 2000 }}
                loopedSlides={4}
                spaceBetween={20}
                className="mySwiper"
              >
                <Grid item xs={10}>
                  <SwiperSlide>
                    <div className={classes.process_image_xs}>
                      <img src={procedure.ProcessImage} />
                    </div>
                    <div
                      className={classes.steps}
                      style={{ textAlign: "center" }}
                    >
                      <div className="step">
                        <p className="step-heading">No Antibiotics</p>
                        <p className="step-content">
                          We make sure that no antibiotics are used in the process
                          of cultivating our ready to eat catch.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Grid>
                <Grid item xs={10}>
                  <SwiperSlide>
                    <div className={classes.process_image_xs}>
                      <img src={procedure.ProcessImage2} />
                    </div>
                    <div
                      className={classes.steps}
                      style={{ textAlign: "center" }}
                    >
                      <div className="step">
                        <p className="step-heading">Sustainably Harvested</p>
                        <p className="step-content">
                          Our sustainably harvested mechanisms are in place to
                          align with our goal of sustainably produced seafood.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Grid>
                <Grid item xs={10}>
                  <SwiperSlide>
                    <div className={classes.process_image_xs}>
                      <img src={procedure.ProcessImage1} />
                    </div>
                    <div className={classes.steps}>
                      <div className="step" style={{ textAlign: "center" }}>
                        <p className="step-heading">
                          Never Farmed or Genetically Modified
                        </p>
                        <p className="step-content">
                          Our meals are free from any farming and genetic
                          modifications to ensure you get a healthy dose of
                          seafood.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Grid>
                <Grid item xs={10}>
                  <SwiperSlide>
                    <div className={classes.process_image_xs}>
                      <img src={procedure.ProcessImage3} />
                    </div>
                    <div
                      className={classes.steps}
                      style={{ textAlign: "center" }}
                    >
                      <div className="step">
                        <p className="step-heading">Flash Frozen</p>
                        <p className="step-content">
                          Our seafood packages are frozen and shipped with dry ice in order to maintain the highest quality until it reaches you completely fresh.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Grid>
                <Grid item xs={10}>
                  <SwiperSlide>
                    <div className={classes.process_image_xs}>
                      <img src={procedure.ProcessImage4} />
                    </div>
                    <div
                      className={classes.steps}
                      style={{ textAlign: "center" }}
                    >
                      <div className="step">
                        <p className="step-heading">No Antibiotics</p>
                        <p className="step-content">
                          We make sure that no antibiotics are used in the process
                          of cultivating our ready to eat catch.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Grid>
                <Grid item xs={10}>
                  <SwiperSlide>
                    <div className={classes.process_image_xs}>
                      <img src={procedure.ProcessImage5} />
                    </div>
                    <div
                      className={classes.steps}
                      style={{ textAlign: "center" }}
                    >
                      <div className="step">
                        <p className="step-heading">Sustainably Harvested</p>
                        <p className="step-content">
                          Our sustainably harvested mechanisms are in place to
                          align with our goal of sustainably produced seafood.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Grid>
                <Grid item xs={10}>
                  <SwiperSlide>
                    <div className={classes.process_image_xs}>
                      <img src={procedure.ProcessImage6} />
                    </div>
                    <div className={classes.steps}>
                      <div className="step" style={{ textAlign: "center" }}>
                        <p className="step-heading">
                          Never Farmed or Genetically Modified
                        </p>
                        <p className="step-content">
                          Our meals are free from any farming and genetic
                          modifications to ensure you get a healthy dose of
                          seafood.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Grid>
              </Swiper>
            </Grid>
          </div>
        ) : (
          <div id="production">
            <Grid
              container
              spacing={isSmall ? 3 : 5}
              direction="row"
              justify={isSmall ? "space-evenly" : "center"}
              alignItems="center"
              className={classes.process_section}
            >
              <Grid item xs={12} className="title">
                <h3>Why Us?</h3>
              </Grid>

              <Grid item sm={5} xs={6} md={3} className={classes.steps}>
                <div
                  className="step"
                  data-sal="slide-right"
                  data-sal-delay="0"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-back"
                >
                  <button
                    className="circle"
                    style={{
                      background:
                        "#fff"
                    }}
                  // onClick={handleClick1}
                  >
                    <span style={{ color: "#00000052" }}>
                      1
                    </span>
                  </button>
                  <p className="step-heading">No Antibiotics</p>

                  <p className="step-content">
                    We make sure that no antibiotics are used in the process of
                    cultivating our ready to eat catch.
                  </p>
                </div>
                <div
                  className="step"
                  style={{ paddingTop: "30px" }}
                  data-sal="slide-right"
                  data-sal-delay="0"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-back"
                >
                  <button
                    className="circle"
                    style={{
                      background: `${focuss1
                        ? `linear-gradient(
                    -135deg
                    ,#1e6db5, #3d55a4)`
                        : "#fff"
                        }`,
                    }}
                  // onClick={handleClick2}
                  >
                    <span style={{ color: `${focuss1 ? "#fff" : "#00000052"}` }}>
                      3
                    </span>
                  </button>
                  <p className="step-heading">Sustainably Harvested</p>
                  <p className="step-content">
                    Our sustainably harvested mechanisms are in place to align
                    with our goal of sustainably produced seafood.
                  </p>
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={6} >


                <Swiper slidesPerView={1} centeredSlides={false} loop={true} loopedSlides={7} autoplay={{ "delay": 5000 }} className="mySwiper">

                  <SwiperSlide className="process-slider">
                    <div className={classes.process_image}>
                      <img src={procedure.ProcessImage} />

                    </div>

                  </SwiperSlide>
                  <SwiperSlide className="process-slider">
                    <div className={classes.process_image}>
                      <img src={procedure.ProcessImage1} />

                    </div>

                  </SwiperSlide>
                  <SwiperSlide className="process-slider">
                    <div className={classes.process_image}>
                      <img src={procedure.ProcessImage2} />

                    </div>

                  </SwiperSlide>
                  <SwiperSlide className="process-slider">
                    <div className={classes.process_image}>
                      <img src={procedure.ProcessImage3} />

                    </div>

                  </SwiperSlide>
                  <SwiperSlide className="process-slider">
                    <div className={classes.process_image}>
                      <img src={procedure.ProcessImage4} />

                    </div>

                  </SwiperSlide>
                  <SwiperSlide className="process-slider">
                    <div className={classes.process_image}>
                      <img src={procedure.ProcessImage5} />

                    </div>

                  </SwiperSlide>
                  <SwiperSlide className="process-slider">
                    <div className={classes.process_image}>
                      <img src={procedure.ProcessImage6} />

                    </div>

                  </SwiperSlide>

                </Swiper>

              </Grid>
              <Grid
                item
                sm={5}
                xs={6}
                md={3}
                className={classes.step_2}
                style={{ marginTop: "20px" }}
              >
                <div
                  className="step"
                  data-sal="slide-left"
                  data-sal-delay="0"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-back"
                >
                  <button
                    className="circle"
                    style={{
                      background: `${focuss2
                        ? `linear-gradient(
                    -135deg
                    ,#1e6db5, #3d55a4)`
                        : "#fff"
                        }`,
                    }}
                  // onClick={handleClick3}
                  >
                    <span style={{ color: `${focuss2 ? "#fff" : "#00000052"}` }}>
                      2
                    </span>
                  </button>
                  <p className="step-heading">
                    Never Farmed or Genetically Modified
                  </p>
                  <p className="step-content">
                    Our meals are free from any farming and genetic modifications
                    to ensure you get a healthy dose of seafood.
                  </p>
                </div>
                <div
                  className="step"
                  data-sal="slide-left"
                  data-sal-delay="0"
                  data-sal-duration="1500"
                  data-sal-easing="ease-out-back"
                >
                  <button
                    className="circle"
                    style={{
                      background: `${focuss3
                        ? `linear-gradient(
                    -135deg
                    ,#1e6db5, #3d55a4)`
                        : "#fff"
                        }`,
                    }}
                  // onClick={handleClick4}
                  >
                    <span style={{ color: `${focuss3 ? "#fff" : "#00000052"}` }}>
                      4
                    </span>
                  </button>
                  <p className="step-heading">Fresh</p>

                  <p className="step-content">
                    Our seafood packages are frozen and shipped with dry ice in order to maintain the highest quality until it reaches you completely fresh.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        )
      } */}

      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.export_section}
      >
        <Grid item xs={12} sm={12} className="title">
          <h3>Countries We Work With</h3>
          <p>
            Harvested from the seas of Karachi and prepared in Thailand, ready
            to be exported worldwide. We ship to multiple countries across the
            world and are constantly expanding.
          </p>
        </Grid>
      </Grid>

      <div
        style={{ backgroundColor: "#F7F8FB" }}
        data-sal="zoom-out"
        data-sal-delay="0"
        data-sal-duration="500"
        data-sal-easing="ease-in-back"
      >
        <Swiper
          slidesPerView={isMob ? 1 : isXSmall ? 2 : isMedSmall ? 3 : 4}
          spaceBetween={30}
          centeredSlides={false}
          loop={true}
          loopedSlides={15}
          autoplay={{ delay: 2000 }}
          className="Swiper-flags"
        >
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.China} />
            </div>
            <h4>China</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Bahrain} />
            </div>
            <h4>Bahrain</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Indonesia} />
            </div>
            <h4>Indonesia</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Japan} />
            </div>
            <h4>Japan</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Korea} />
            </div>
            <h4>Korea</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Malaysia} />
            </div>
            <h4>Malaysia</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Singapore} />
            </div>
            <h4>Singapore</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Taiwan} />
            </div>
            <h4>Taiwan</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Thailand} />
            </div>
            <h4>Thailand</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.UAE} />
            </div>
            <h4>UAE</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Vietnam} />
            </div>
            <h4>Vietnam</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.USA} />
            </div>
            <h4>USA</h4>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide3">
            <div className="swiper-img">
              <img src={flag_images.Oman} />
            </div>
            <h4>Oman</h4>
          </SwiperSlide>
        </Swiper>
      </div>
      <div >
        <Grid
          container
          spacing={0}
          direction="row"
          justify={isSmall ? "center" : "start"}
          alignItems="center"
          className={classes.contact_section}

          style={{ paddingTop: "50px", paddingBottom: "200px" }}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={6}
            xl={6}
            className={classes.map_image}
          >
            {/* <img
              src={MapBox}
              alt="ocean fishry address"
              className={classes.map_box}
            /> */}
            <img src={MapImage} alt="ocean fishry map" className={classes.map} />

          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={5}
            xl={5}
            className={classes.contact_form}

          >

            <ContactUs isSmall={isSmall} />
          </Grid>


          <div class="ocean">
            <div class="wave wave1"></div>
            <div class="wave wave2"></div>
            <div class="wave wave3"></div>
            <div class="wave wave4"></div>
          </div>
        </Grid>
      </div>

    </Layout >
  )
}
export default HomePage

export const pagequery = graphql`
  query MyQuery {
    allWpProduct(
      filter: {
        productTypes: { nodes: { elemMatch: { name: { eq: "simple" } } } }
      }
    ) {
      edges {
        node {
          name
          sku
          slug
          shortDescription
          image {
            sourceUrl
          }
          id
        }
      }
    }
  }
`
