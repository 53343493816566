import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import SwiperCore, {
    Pagination,
  Autoplay} from 'swiper/core';
  import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import './Slider.css';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';





const FishSlider = () => {
  const theme = useTheme();
  const isXsSmall = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMed = useMediaQuery(theme.breakpoints.down("md"))
    const fishData = useStaticQuery(graphql`
    query fishQuery {
      allWpProduct(
        filter: {productCategories: {nodes: {elemMatch: {slug: {eq: "fish"}}}}}
      ) {
        edges {
          node {
            name
            id
            slug
            image {
              sourceUrl
            }
          }
        }
      }
    }
    `);
    SwiperCore.use([Pagination,Autoplay]);
    
    return(
        
        <Swiper slidesPerView={isXsSmall ? 1 : isSmall ? 2 : isMed ? 3 : 4} spaceBetween={30} centeredSlides={false} loop={true} loopedSlides={7} autoplay={{"delay":2000}} className="mySwiper">
        {fishData.allWpProduct.edges.map(mydata => (
          <SwiperSlide className="swiper-slide2" key={mydata.node.id}><Link to={`/product/${mydata.node.slug}`}><img  src={mydata.node.image.sourceUrl}/></Link><Link to={`/product/${mydata.node.slug}`}><h4>{mydata.node.name}</h4></Link></SwiperSlide>
        ))}
  </Swiper>
        
    )
}

export default FishSlider;



/* K7kBl6sNhfrlSTx8GK6msREsncJQ6QvN */