import React from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql, Link} from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwiperCore, {
    Pagination,
  Autoplay} from 'swiper/core';
  import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import './Slider.css';
import FishSlider from './FishSlider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CephalopodsSlider from './CephalopodsSlider';
import CrustaceansSlider from './CrustaceansSlider';

function TabPanel(props) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "50px 0px 50px 0px",
    backgroundColor: "#F7F8FB",
    
  },
  
  tabstyle:{
    
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: "18px",
      color: "#000000a3",
      
    '&.Mui-selected .MuiTab-wrapper': {
      background: `linear-gradient( 
        -135deg
         ,#1e6db5, #3d55a4)`,
      borderRadius:"25px",
      padding:"5px 25px",
      color:"#f9f9f9",
  },
  

  "&:hover": {
    background: "none",
  }
  },

  title:{
    color:"#3D55A4",
    fontSize:"48px",
    textAlign:"center",
    marginBottom: "25px",
    fontFamily: "Roboto ,sans-serif",
    fontWeight: "900",
    paddingTop:"1px",
    "& > h3":{
      margin:"0px",
    },
  },
  tabsbody:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    marginBottom:"50px",
    flexWrap:'wrap',
  },
producttitle:{
  textAlign:"center",
}


}));
SwiperCore.use([Pagination,Autoplay]);



export default function SimpleTabs({data}) {
  const theme = useTheme();
  const isXsSmall = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMed = useMediaQuery(theme.breakpoints.down("md"))
  const fishData = useStaticQuery(graphql`
  query allcatQuery {
    allWpProduct {
      edges {
        node {
          name
          id
          slug
          image {
            sourceUrl
          }
        }
      }
    }
  }
  
  
  
  `);
  


  console.log(fishData,"This")
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}><h3>Our Products</h3></div>
      <div className={classes.tabsbody}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{
      style: {
          display: "none",
      },
      
    }} className={classes.tabs}>
          <Tab label="All" {...a11yProps(0)} className={classes.tabstyle} wrapped={true} fullWidth={true}/>
          <Tab label="FISH" {...a11yProps(1)} className={classes.tabstyle} fullWidth={true}/>
          <Tab label="CEPHALOPODS" {...a11yProps(2)} className={classes.tabstyle} fullWidth={true}/>
          <Tab label="SHELLFISH" {...a11yProps(3)} className={classes.tabstyle} fullWidth={true}/>
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
      <Swiper slidesPerView={isXsSmall ? 1 : isSmall ? 2 : isMed ? 3 : 4} spaceBetween={30} centeredSlides={false} loop={true} loopedSlides={7} autoplay={{"delay":2000}} className="mySwiper">
        {data.allWpProduct.edges.map(mydata => (
          <SwiperSlide className="swiper-slide2" key={mydata.node.id}><div><Link to={`/product/${mydata.node.slug}`}><img  src={mydata.node.image.sourceUrl}/></Link></div><Link to={`/product/${mydata.node.slug}`}><h4 className={classes.producttitle}>{mydata.node.name}</h4></Link></SwiperSlide>
        ))}
  </Swiper>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <FishSlider />
      </TabPanel>
      <TabPanel value={value} index={2}>
      <CephalopodsSlider />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <CrustaceansSlider />
      </TabPanel>
    </div>
  );
}

